import ContactForms from "../../components/ContactForms/ContactForms";

import imagePaymentGateway from "../../assets/thumbs/image-payment-gateway.webp";
import imagePaymentCheckout from "../../assets/img/payment-checkout.webp";

import agilityPaymentIcon from "../../assets/icons/agility-payments.svg";
import highestConversationRateIcon from "../../assets/icons/highest-conversation-rate.svg";
import documentationIntegrationIcon from "../../assets/icons/documentation-integration.svg";
import creditToAccountIcon from "../../assets/icons/credit-to-account.svg";

export default function PaymentGateway({ screenSize }) {
  return (
    <>
      <section className="bg-inncash xl:bg-cover bg-no-repeat flex flex-col md:flex-row justify-center md:justify-between md:py-16 py-10 items-center min-[1700px]:px-36 xl:px-20 lg:px-12 md:px-2 md:mt-20 mt-[81px]">
        <div className="flex flex-col md:items-start items-center text-white md:ml-3 xl:ml-0">
          <h1 className="font-bold text-3xl lg:text-5xl min-[1700px]:text-6xl">Gateway de</h1>
          <h1 className="font-bold text-4xl lg:text-6xl md:mb-7 mb-4 min-[1700px]:text-7xl">Pagamento</h1>
          <p className="text-sm xl:text-lg min-[1700px]:text-xl">Facilitamos a conexão da sua empresa com os</p>
          <p className="text-sm xl:text-lg min-[1700px]:text-xl">principais métodos de pagamento, tornando o</p>
          <p className="text-sm xl:text-lg min-[1700px]:text-xl">processo de compra mais silmples e ágil</p>
          <p className="text-sm xl:text-lg min-[1700px]:text-xl mb-6">impulsionando as suas vendas.</p>
          <button className="text-sm md:text-sm min-[1700px]:text-lg font-bold rounded-3xl px-4 py-1 md:px-9 md:py-3 bg-green-500-attention hover:bg-green-500-attention-hover transition duration-150">
            <a href="#contact-section">FALE COM UM ESPECIALISTA</a>
          </button>
        </div>
        <span className="flex md:justify-end justify-center relative mt-8 md:mt-0">
          <img
            src={imagePaymentGateway}
            alt="Thumbnail vídeo do youtube soluções de pagamentos Inncash"
            className="rounded-2xl md:w-[450px] lg:w-[550px] xl:w-[650px] w-[340px]"
          />
        </span>
      </section>

      <section className="mt-5 md:mt-12 mx-3">
        {screenSize <= 768 ? (
          <div className="font-bold text-[16px] flex flex-col justify-center items-center md:mt-6 mb-8">
            <h1>Simplifique seu recebimento e venda muito mais</h1>
          </div>
        ) : (
          <div className="md:flex justify-center text-md mb-8 mt-6 md:mt-0">
            <h1 className="md:text-xl xl:text-2xl font-bold">Simplifique seu recebimento e venda muito mais</h1>
          </div>
        )}
        <div className="text-[15px] md:text-xl xl:text-3xl flex flex-col items-center xl:mb-18 md:mb-0">
          <h1>Conecte aos principais meios de pagamento</h1>
        </div>

        <div className="md:grid grid-cols-2 xl:mx-24 xl:mt-16 max[380px]-mx-2">
          <div className="flex flex-col xl:flex-row items-center xl:items-start md:justify-center mt-14 xl:mt-0">
            <img src={agilityPaymentIcon} alt="Ícone azul de cifrão" className="w-20 xl:w-22 xl:mt-8" />
            <div className="flex flex-col items-center xl:items-start md:ml-3 xl:ml-6 mt-4 xl:mt-0 text-sm">
              <h2 className="text-blue-inncash font-bold text-lg xl:text-xl mb-5">Agilidade nos pagamentos</h2>
              <p>Tenha em mãos um processo rápido e prático </p>
              <p>devido à nossa conexão direta com adquirentes e</p>
              <p>instituições financeiras, reduzindo o tempo de </p>
              <p>espera para finalização de uma compra.</p>
            </div>
          </div>

          <div className="flex flex-col xl:flex-row items-center xl:items-start md:justify-center mt-10 xl:mt-0">
            <img
              src={highestConversationRateIcon}
              alt="ïcone azul de cifrão dentro de uma núvem"
              className="w-18 md:w-16 xl:mt-5"
            />
            <div className="flex flex-col items-center xl:items-start md:ml-3 xl:ml-6 mt-4 xl:mt-0 text-sm">
              <h2 className="text-blue-inncash font-bold text-lg max-[380px]:text-[16px] xl:text-xl mb-5">
                Maior taxa de conversão
              </h2>
              <p>Com a agilidade oferecida na aprovação das compras,</p>
              <p>minimizamos a chance de abandono de carrinho,</p>
              <p>tornando mais provável a conclusão do processo de</p>
              <p>compra pelo cliente.</p>
            </div>
          </div>

          <div className="flex flex-col xl:flex-row items-center xl:items-start md:justify-center mt-16 text-sm">
            <img
              src={documentationIntegrationIcon}
              alt="Ícone azul com um cifrão dentro de uma engrenagem envolte de flechas"
              className="w-18 md:w-16 xl:mt-5"
            />
            <div className="flex flex-col items-center xl:items-start md:ml-3 xl:ml-6 mt-4 xl:mt-0">
              <h2 className="text-blue-inncash font-bold text-lg xl:text-xl mb-5">Documentação para integração</h2>
              <p>Disponibilizamos a documentação e suporte para</p>
              <p>desenvolvimento das integrações com as nossas</p>
              <p>APIs.</p>
            </div>
          </div>

          <div className="flex flex-col xl:flex-row items-center xl:items-start md:justify-center mt-16 text-sm">
            <img src={creditToAccountIcon} alt="Ícone azul de banco" className="w-18 md:w-16 xl:mt-5" />
            <div className="flex flex-col items-center xl:items-start md:ml-3 xl:ml-6 mt-4 xl:mt-0">
              <h2 className="text-blue-inncash font-bold text-lg xl:text-xl mb-5">
                Receba o crédito na sua própria conta
              </h2>
              <p>Você tem a flexibilidade de escolher o adquirente ou</p>
              <p>a instituição financeira com a qual deseja trabalhar.</p>
              <p>São mais de 200 instituições conectadas. Sem</p>
              <p>contas digitais adicionais e sem complicação!</p>
            </div>
          </div>
        </div>
      </section>

      <section className="mt-10 mb-10 flex items-center justify-center">
        <div className="bg-gray-100-inncash py-8 xl:px-36 px-10 rounded-3xl flex flex-col lg:flex-row items-center lg:justify-between justify-center mx-4">
          <div className="flex flex-col items-center lg:items-start text-blue-inncash font-bold text-xl md:text-2xl xl:text-xl mb-4 lg:mb-0 2xl:mr-28 xl:mr-10 lg:mr-8">
            <h1 className="md:text-3xl text-lg lg:text-2xl">Múltiplas opções de pagamento.</h1>
          </div>
          <p className="lg:w-[400px] lg:text-sm xl:text-normal">
            Seu cliente pode escolher se prefere pagar por pix, boleto convencional, boleto híbrido ou cartão. E você
            pode ficar tranquilo, pois todos os métodos estarão integrados ao banco de sua escolha e ao seu sistema
            interno.
          </p>
        </div>
      </section>

      <section className="my-14 flex items-center justify-center">
        <img
          src={imagePaymentCheckout}
          alt="Thumbnail vídeo do youtube soluções de pagamentos Inncash"
          className="rounded-2xl md:w-[450px] lg:w-[550px] xl:w-[650px] w-[340px]"
        />
      </section>

      <section id="contact-section">
        <ContactForms />
      </section>
    </>
  );
}
