import { useRef } from "react";
import { Link } from "react-router-dom";

import logoInncashBlack from "../assets/img/logotipo-inncash-black.svg";
import phoneIcon from "../assets/icons/phone.svg";
import contactIcon from "../assets/icons/contact.svg";
import linkedInIcon from "../assets/icons/linkedin.svg";
import instagramIcon from "../assets/icons/instagram.svg";
import youtubeIcon from "../assets/icons/youtube.svg";

export default function Footer({ locationPath }) {
  const linkRef = useRef(null);

  function forwardToForms() {
    setTimeout(() => {
      linkRef.current.click();
    }, 1500);
  }

  return (
    <>
      {/* hidden tag responsible for directing to the contacts section */}
      <a href="#contact-section" ref={linkRef} className="hidden">
        forwardToForms
      </a>
      <section>
        <div className="flex flex-col h-[600px] md:h-[340px] mb-10 md:mb-0">
          <div className="flex flex-col md:flex-row md:items-start items-center md:justify-between xl:w-[1100px] xl:ml-40 lg:ml-20 2xl:ml-60 md:mx-10 md:mt-18">
            <span>
              <a href="#home-section">
                <img
                  src={logoInncashBlack}
                  alt="Logotipo preto Inncash"
                  className="w-[180px] xl:w-[240px] h-[43px] md:mt-18 md:-ml-5 mt-10"
                />
              </a>
            </span>
            <span className="flex flex-col items-center md:items-start font-bold mt-8 md:mt-0">
              <h2>CONTATE-NOS</h2>
              <span className="flex mt-8">
                <img src={phoneIcon} alt="Ícone de Telefone" className="w-[21px] h-[21px] mr-2" />
                <a href="tel:+554834134303">+55 (48) 3413-4303</a>
              </span>
              <span className="flex mt-5">
                <img src={contactIcon} alt="Ícone de Contato" className="w-[21px] h-[21px] mr-2" />
                <a href="mailto:contato@inn.cash">contato@inn.cash</a>
              </span>
              <span className="flex mt-5">
                <a href="https://www.linkedin.com/company/inncash/" target="blank">
                  <img src={linkedInIcon} alt="Logotipo do LinkedIn" className="w-[20px] h-[20px] mr-6" />
                </a>
                <a href="https://www.instagram.com/inn.cash/" target="blank">
                  <img src={instagramIcon} alt="Logotipo do Instagram" className="w-[20px] h-[20px] mr-6" />
                </a>
                <a href="https://www.youtube.com/@inncash8003" target="blank">
                  <img src={youtubeIcon} alt="Logotipo do Youtube" className="w-[25px] h-[20px] mr-6" />
                </a>
              </span>
            </span>
            <span className="flex flex-col md:items-start items-center font-bold mt-8 md:mt-0">
              <h2>NAVEGAÇÃO</h2>
              <div className="flex mt-8">
                <div className="flex flex-col font-normal">
                  <div className=" mb-2">
                    <h2 className="font-bold">Soluções</h2>
                  </div>
                  <Link to="/solucoes-em-pagamentos" className="hover:text-blue-inncash">
                    Pagamentos
                  </Link>
                  <Link to="/solucoes-em-cobrancas" className="mt-1 hover:text-blue-inncash">
                    Cobranças
                  </Link>
                  <Link to="/solucoes-para-conciliacao-bancaria" className="mt-1 hover:text-blue-inncash">
                    Conciliação Bancária
                  </Link>
                  <Link to="/solucoes-para-fluxo-de-caixa" className="mt-1 hover:text-blue-inncash">
                    Fluxo de Caixa
                  </Link>
                </div>

                <div className="flex flex-col ml-18 max-[380px]:-mr-2 max-[380px]:ml-5">
                  <Link to="/quem-somos" className="hover:text-blue-inncash">
                    Quem Somos
                  </Link>
                  <Link to="/seja-um-parceiro" className="mt-1 hover:text-blue-inncash">
                    Seja um Parceiro
                  </Link>
                  <Link to={"/"} className="mt-1 hover:text-blue-inncash" onClick={forwardToForms}>
                    Contato
                  </Link>
                </div>
              </div>
            </span>
          </div>
          <div className="flex justify-center mb-12 mt-12">
            <p className="mb-12">
              © 2023{" "}
              <a
                href="https://innovaconnect.com.br/"
                className="text-decoration-line: underline"
                target="_blank"
                rel="noreferrer"
              >
                Innova Connect Sistemas Ltda.
              </a>
            </p>
          </div>
        </div>
      </section>
    </>
  );
}
