import { Controller } from "react-hook-form";

export default function PaymentMethods({
  control,
  paymentMethods,
  paymentMethodsContext,
  paymentMethodsBuilder,
  setCheckBankSlip,
  checkBankSlip,
  setCheckPix,
  checkPix,
  setCheckCard,
  checkCard,
  setCheckDirectDebit,
  checkDirectDebit,
}) {
  function selectPaymentMethod(paymentMethod) {
    switch (paymentMethod) {
      case "bankSlip":
        setCheckBankSlip(!checkBankSlip);
        paymentMethodsBuilder(paymentMethod);
        break;
      case "pix":
        setCheckPix(!checkPix);
        paymentMethodsBuilder(paymentMethod);
        break;
      case "card":
        setCheckCard(!checkCard);
        paymentMethodsBuilder(paymentMethod);
        break;
      case "directDebit":
        setCheckDirectDebit(!checkDirectDebit);
        paymentMethodsBuilder(paymentMethod);
        break;
      default:
        console.error(`selectPaymentMethod - Sorry, we are out of ${paymentMethod}.`);
    }
  }

  return (
    <>
      <div className="mt-6 flex flex-col">
        {paymentMethods.map((paymentMethod, index) => {
          const checkboxName = `paymentMethods[${index}]`;

          return (
            <span key={index} className="flex items-center mb-6">
              <Controller
                name={checkboxName}
                control={control}
                defaultValue={false}
                render={({ field }) => (
                  <input
                    id={checkboxName}
                    name={checkboxName}
                    type="checkbox"
                    value={paymentMethod.name}
                    checked={paymentMethodsContext ? paymentMethodsContext[index] : null}
                    onClick={() => {
                      selectPaymentMethod(paymentMethod.method);
                    }}
                    {...field}
                    className="h-[19px] w-[19px] cursor-pointer appearance-none border-[3.7px] rounded-sm transition-all checked:bg-gray-350-inncash"
                  />
                )}
              />
              <label htmlFor={checkboxName} className="ml-1 cursor-pointer">
                {paymentMethod.name}
              </label>
            </span>
          );
        })}
      </div>
    </>
  );
}
