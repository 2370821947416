import React, { useState, forwardRef } from "react";
import { useFormContext } from "react-hook-form";
import InputMask from "react-input-mask";

import ListCompanies from "./ListCompanies";
import { validCNPJ } from "../../validation/validationCnpj";
import * as yup from "yup";

const Companies = forwardRef(() => {
  const { watch, setValue } = useFormContext();

  const companies = watch("companies", []);

  const [corporateName, setCorporateName] = useState("");
  const [cnpj, setCnpj] = useState("");
  const [hasBranch, setHasBranch] = useState(false);
  const [howManyBranches, setHowManyBranches] = useState("");
  const [errors, setErrors] = useState({});

  const validationSchema = yup.object().shape({
    corporateName: yup.string().required("Informe a razão social"),
    cnpj: yup
      .string()
      .required("Informe o CNPJ")
      .matches(/^\d{2}\.\d{3}\.\d{3}\/\d{4}-\d{2}$/, "CNPJ inválido")
      .test("unique-cnpj", "CNPJ já incluído", function (value) {
        if (!value) return true;
        return companies.every((company) => !company.cnpj.includes(value));
      })
      .test("valid-cnpj", "CNPJ inválido", function (value) {
        if (!value) return true;
        return validCNPJ(value);
      }),

    howManyBranches: yup
      .string()
      .test("custom-validation", "Quantidade de filiais deve ser informada", function (value) {
        if (this.parent.hasBranch) {
          return value !== "";
        }
        return true;
      })
      .test("custom-validation", "Quantidade de filiais deve ser númerico e maior que 0", function (value) {
        if (this.parent.hasBranch) {
          return /^[1-9]+$/.test(value);
        }
        return true;
      }),
  });

  function notHasBranch() {
    setHasBranch(false);
    setHowManyBranches("");
  }

  function hasBrach() {
    setHasBranch(true);
    setHowManyBranches("");
  }

  function handleAddCompany(event) {
    event.preventDefault();
    let amountBranches = howManyBranches;

    if (!hasBranch) {
      amountBranches = "0";
    }

    const newCompany = {
      corporateName: corporateName,
      cnpj: cnpj,
      hasBranch: hasBranch,
      howManyBranches: amountBranches,
    };

    validationSchema
      .validate(newCompany)
      .then(() => {
        setValue("companies", [newCompany, ...companies]);

        setCorporateName("");
        setCnpj("");
        setHasBranch(false);
        setHowManyBranches("");

        setErrors({});
      })
      .catch((error) => {
        if (error.message) {
          setErrors({ message: error.message });
        } else if (error.inner) {
          const validationErrors = {};
          error.inner.forEach((err) => {
            validationErrors[err.path] = err.message;
          });
          setErrors(validationErrors);
        }
      });
  }

  function handleDeleteCompany(index) {
    const currentCompanies = watch("companies", []);
    const updatedCompanies = currentCompanies.filter((_, i) => i !== index);

    setValue("companies", updatedCompanies);
  }

  return (
    <>
      <div>
        <div className="mt-5 flex flex-col md:flex-row items-center">
          <div className="md:flex max-[380px]:px-2">
            <input
              type="text"
              placeholder="Razão Social*"
              className="placeholder:text-black placeholder:text-sm bg-gray-70-inncash rounded-full px-6 py-2 md:w-[198px] w-full"
              value={corporateName}
              onChange={(e) => setCorporateName(e.target.value)}
            />
            <InputMask
              type="text"
              placeholder="CNPJ*"
              className="placeholder:text-black placeholder:text-sm bg-gray-70-inncash rounded-full px-6 py-2 md:w-[198px] w-full md:ml-3 mt-4 md:mt-0"
              mask="99.999.999/9999-99"
              value={cnpj}
              onChange={(e) => setCnpj(e.target.value)}
            />
          </div>
          <div className="flex items-center mt-4 md:mt-0">
            <h3 className="ml-4 max-[380px]:ml-0">Possui filiais?*</h3>
            <input
              type="radio"
              id="yesBranch"
              name="branchExist"
              className="ml-4 h-5 w-5 accent-gray-350-inncash cursor-pointer"
              checked={hasBranch}
              value={hasBranch}
              onChange={hasBrach}
            />
            <label htmlFor="yesBranch" className="ml-1">
              SIM
            </label>
            <input
              type="radio"
              id="noBranch"
              name="branchExist"
              className="ml-4 h-5 w-5 accent-gray-350-inncash cursor-pointer"
              checked={!hasBranch}
              value={hasBranch}
              onChange={notHasBranch}
            />
            <label htmlFor="noBranch" className="ml-1">
              NÃO
            </label>
            <input
              type="text"
              placeholder="Quantas?*"
              className={
                hasBranch
                  ? "bg-gray-70-inncash placeholder:text-black placeholder:text-sm rounded-full lg:px-6 px-3 py-2 w-[100px] lg:w-[130px] ml-3"
                  : "text-gray-400 placeholder:text-sm bg-gray-70-inncash rounded-full lg:px-6 px-3 py-2 w-[100px] lg:w-[130px] ml-3"
              }
              style={{ textAlign: "center" }}
              value={howManyBranches}
              onChange={(e) => setHowManyBranches(e.target.value)}
              disabled={!hasBranch}
            />
          </div>
        </div>
        <div className="flex flex-col items-center justify-center mt-5">
          <button className="hover:text-blue-600 transition duration-150" onClick={handleAddCompany}>
            <h2>+ Adicionar empresa</h2>
          </button>
          {errors.message && <span className="text-red-500 font-bold text-xs">{errors.message}</span>}
        </div>
        <div className={companies.length > 0 ? "border-b-2 border-gray-70-inncash mt-5" : "hidden"} />
        <div className="flex flex-col">
          <ListCompanies companies={companies} handleDeleteCompany={handleDeleteCompany} />
        </div>
      </div>
    </>
  );
});

export default Companies;
